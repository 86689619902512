export const endpoints = {
	user: 'http://user.jasatirta2.id/'
};

export const zones = [
	'Citarum Hulu',
	'Citarum Tengah',
	'Citarum Hilir',
	'Saluran Tarum Utara',
	'Saluran Tarum Barat',
	'DAS Sungai Bekasi',
	'Saluran Tarum Timur',
	'WS Seputih Sekampung',
	'WS Cidanau Ciujung Cidurian',
	'WS Cimanuk Cisanggrung',
];

export const scada_locations = [
    'Buaran',
    'Pulogadung',
    'ATR',
    'Pumping & DVC',
    'IVC',
    'Surge Tower'
];

export const translate_scada_columns = [
	{
		'level_intake': 'TMA<br><small>(mdpl)</small>',
		'flow_buaran1': 'Flow Buaran 1<br><small>(m3/detik)</small>',
		'flow_buaran2': 'Flow Buaran 2<br><small>(m3/detik)</small>',
		'totalizer_buaran1': 'Totalizer Buaran 1<br><small>(Bar)</small>',
		'totalizer_buaran2': 'Totalizer Buaran 2<br><small>(Bar)</small>',
	},
	{
		'level_tma': 'TMA<br><small>(mdpl)</small>',
		'open_channel_inlet':'Flow Open Channel Intake<br><small>(m3/detik)</small>'
	},
	{
		'atr_downstream': 'Downstream<br><small>(mdpl)</small>',
		'atr_limpasan': 'Limpasan<br><small>(m)</small>',
		'atr_upstream_left': 'Upstream Left<br><small>(mdpl)</small>',
		'atr_upstream_right': 'Upstream Right<br><small>(mdpl)</small>',
		'temperatur': 'Temperatur<br><small>(<sup>o</sup>C)</small>',
		'turbidity': 'Turbidity<br><small>(NTU)</small>'
	},
	{
		'flowrate_ipasatu': 'Flowrate IPA 1<br><small>(m3/detik)</small>',
		'flowrate_ipadua': 'Flowrate IPA 2<br><small>(m3/detik)</small>',
		'pressure_ipasatu': 'Pressure IPA 1<br><small>(Bar)</small>',
		'pressure_ipadua': 'Pressure IPA 2<br><small>(Bar)</small>',
	},
	{
		'pressure_inlet_satu':'Pressure Inlet 1<br><small>(Bar)</small>',
		'pressure_Inlet_dua':'Pressure Inlet 2<br><small>(Bar)</small>',
		'pressure_Outlet_satu':'Pressure Outlet 1<br><small>(Bar)</small>',
		'pressure_Outlet_dua':'Pressure Outlet 2<br><small>(Bar)</small>',
	},
	{
		'flow_ipasatu': 'Flowrate IPA 1<br><small>(m3/detik)</small>',
		'flow_ipadua': 'Flowrate IPA 2<br><small>(m3/detik)</small>',
		'pressure_downstream_satu': 'Pressure Downstream 1<br><small>(Bar)</small>',
		'pressure_downstream_dua': 'Pressure Downstream 2<br><small>(Bar)</small>',
		'pressure_upstream_satu': 'Pressure Upstream 1<br><small>(Bar)</small>',
		'pressure_upstream_dua': 'Pressure Upstream 2<br><small>(Bar)</small>',
		'tma': 'TMA<br><small>(mdpl)</small>'
	}
];

export const scada_chart_options = {
	buaran: {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				ticks: {
					autoSkip: true,
					maxTicksLimit: 6,
					maxRotation: 0,
					fontSize: 10
				}
			}],
			yAxes: [
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "left",
					id: "y-axis-1",
					scaleLabel: {
						display: true,
						labelString: "mdpl"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-2",
					scaleLabel: {
						display: true,
						labelString: "m3/detik"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-3",
					scaleLabel: {
						display: true,
						labelString: "Bar"
					}
				}
			],
		},
		legend: {
			labels: {
				boxWidth: 12
			}
		}
	},
	pulogadung: {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				ticks: {
					autoSkip: true,
					maxTicksLimit: 6,
					maxRotation: 0,
					fontSize: 10
				}
			}],
			yAxes: [
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "left",
					id: "y-axis-1",
					scaleLabel: {
						display: true,
						labelString: "mdpl"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-2",
					scaleLabel: {
						display: true,
						labelString: "m3/detik"
					}
				}
			],
		},
		legend: {
			labels: {
				boxWidth: 12
			}
		}
	},
	atr: {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				ticks: {
					autoSkip: true,
					maxTicksLimit: 6,
					maxRotation: 0,
					fontSize: 10
				}
			}],
			yAxes: [
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "left",
					id: "y-axis-1",
					scaleLabel: {
						display: true,
						labelString: "mdpl"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "left",
					id: "y-axis-2",
					scaleLabel: {
						display: true,
						labelString: "m"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-3",
					scaleLabel: {
						display: true,
						labelString: "oC"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-4",
					scaleLabel: {
						display: true,
						labelString: "NTU"
					}
				}
			],
		},
		legend: {
			labels: {
				boxWidth: 12
			}
		}
	},
	dvc: {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				ticks: {
					autoSkip: true,
					maxTicksLimit: 6,
					maxRotation: 0,
					fontSize: 10
				}
			}],
			yAxes: [
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "left",
					id: "y-axis-1",
					scaleLabel: {
						display: true,
						labelString: "m3/detik"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-2",
					scaleLabel: {
						display: true,
						labelString: "Bar"
					}
				}
			],
		},
		legend: {
			labels: {
				boxWidth: 12
			}
		}
	},
	ivc: {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				ticks: {
					autoSkip: true,
					maxTicksLimit: 6,
					maxRotation: 0,
					fontSize: 10
				}
			}],
			yAxes: [
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "left",
					id: "y-axis-1",
					scaleLabel: {
						display: true,
						labelString: "Bar"
					}
				}
			],
		},
		legend: {
			labels: {
				boxWidth: 12
			}
		}
	},
	surge_tower: {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				ticks: {
					autoSkip: true,
					maxTicksLimit: 6,
					maxRotation: 0,
					fontSize: 10
				}
			}],
			yAxes: [
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "left",
					id: "y-axis-1",
					scaleLabel: {
						display: true,
						labelString: "mdpl"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-2",
					scaleLabel: {
						display: true,
						labelString: "m3/detik"
					}
				},
				{
					type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
					display: true,
					position: "right",
					id: "y-axis-3",
					scaleLabel: {
						display: true,
						labelString: "Bar"
					}
				}
			],
		},
		legend: {
			labels: {
				boxWidth: 12
			}
		}
	}
}